<template>
  <div id="about">
    <div class="about-phone-container">
      <div class="phone-wrapper">
        <div class="phone-horizontal-wrapper">
          <img id="phone-outer-layer" src="../../../assets/mobile-camera-frame.png"/>
        </div>
      </div>
    </div>
    <div class="homepage-overlay" id="homepage-overlay">
      <div class="homepage-item-top">
        <h1>{{ $t("HOMEPAGE_HEADER") }}</h1>
        <span>{{ $t("HOMEPAGE_CONTENT") }}</span>
        <store-badges :scale="0.65" style="margin: 0;" />
      </div>
      <div class="homepage-item-bottom">
        <span>{{ $t("SWIPE_DOWN_FOR_MORE")}}</span>
        <img class="swipe-down-arrow" src="../../../assets/more-arrow.png" />
      </div>
    </div>
    <div class="background-container">
      <div id="background-wrapper">
        <div id="homepage-background">
          <img id="homepage-background-image" src="../../../assets/mobile-homepage-background.jpg" />
          <div id="homepage-content">
            <div id="homepage-papers" class="homepage-papers-container">
              <img class="homepage-paper" id="paper-3" src="../../../assets/homepagePapers/paper3.png" style="transform-origin: 65.9% 45.2%;" />
              <img class="homepage-paper" id="paper-2" src="../../../assets/homepagePapers/paper2.png" style="transform-origin: 36.1% 54.78%;" />
              <img class="homepage-paper-primary" id="paper-1" src="../../../assets/homepagePapers/paper1.png" style="height: 100%; position: relative;"/>
              <div id="papers-border" style="position: absolute; height: 66.66%; width: 44%; top: 0.5%; left: 50.5%; transform: translateX(-50%);" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="homepage-spacer" />
    <div class="about-container">
      <div class="headers-wrapper" id="about-header-container">
        <div class="header-container">
          <h1>{{ $t("ABOUT_HEADER_LEFT") + " " + $t("ABOUT_HEADER_RIGHT")}}</h1>
        </div>
        <div class="header-container">
          <span>{{ $t("ABOUT_CONTENT_LEFT") + " " + $t("ABOUT_CONTENT_RIGHT") }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { inject, onMounted, onUnmounted } from "vue";

import StoreBadges from "@/components/common/StoreBadges";
import debounce from "lodash.debounce";
import clamp from "lodash.clamp";
import throttle from "lodash.throttle";

export default {
  name: "MobileAbout",
  components: {
    "store-badges": StoreBadges
  },
  setup() {

    const global = inject("global");
    const $ = require("jquery")

    function resizePhone() {
      let maxHeight = parseFloat(document.documentElement.style.getPropertyValue("--vh")) * 75;
      let maxWidth = $(window).width() * 0.95;

      const phoneImg = document.getElementById("phone-outer-layer")

      if(maxHeight * 676 / 1366 < maxWidth) {
        phoneImg.style.width = maxHeight * 676 / 1366 + "px"

        phoneImg.style.height = maxHeight + "px"
      } else {
        phoneImg.style.height = maxWidth * 1366 / 676 + "px"


        phoneImg.style.width = maxWidth + "px"
      }
    }

    const phoneScaler = (height, width, scrollTop) => {


      const phone = document.getElementById("phone-outer-layer");
      const backgroundScaler = document.getElementById("homepage-background");

      const phoneWidth = parseFloat(phone.style.width.substring(0, phone.style.width.length - 2))
      const phoneHeight = parseFloat(phone.style.height.substring(0, phone.style.width.length - 2))


      const phoneOutsideScale = width / phoneWidth * 2.2 - 1
      const scale = (1 + Math.max(0, (height - scrollTop) /height * phoneOutsideScale ))

      if(scrollTop >= height  || phone.style.transform !== "scale(" + scale + ") translate(-50%, -50%)") {

        phone.style.transform = "scale(" + scale + ") translate(-50%, -50%)"
        backgroundScaler.style.width = phoneWidth * scale * 0.905 + "px";
        backgroundScaler.style.height = phoneHeight * scale * 0.96 + "px";

      }

    }

    const appScrollListener = () => {

      const oh = document.documentElement.style.getPropertyValue("--oh")
      const height = parseFloat(oh.substring(0, oh.length - 2)) * 100
      const width = $(window).width()
      const scrollTop = !global.isBodyScrolling ? document.scrollingElement.scrollTop : document.body.scrollTop

      const homepageOverlay = document.getElementById("homepage-overlay")
      const homepageContent = document.getElementById("homepage-content")
      if(homepageOverlay.style.opacity < 1 || scrollTop <= height) {
        const paperThirdTranslateX = -15.9, paperThirdTranslateY = -13.7, paperThirdRotate = -13.9,
            paperSecondTranslateX = 14.3, paperSecondTranslateY = -22.7, paperSecondRotate = 22.84;
        const ratio = clamp((scrollTop - height * 0.4) / (height * 0.4), 0, 1)
        homepageOverlay.style.opacity = 1 - clamp(scrollTop / (height * 0.2), 0, 1)
        document.getElementById("paper-3").style.transform = `translateX(-50%) translate(${paperThirdTranslateX * ratio}%,${paperThirdTranslateY * ratio}%) rotate(${paperThirdRotate * ratio}deg)`
        document.getElementById("paper-2").style.transform = `translateX(-50%) translate(${paperSecondTranslateX * ratio}%,${paperSecondTranslateY * ratio}%) rotate(${paperSecondRotate * ratio}deg)`
        document.getElementById("papers-border").style.border = `2.5px solid rgba(98, 125, 255, ${ Math.pow(ratio, 5)})`
        homepageContent.style.backgroundColor = `rgba(98, 125, 255, ${ 0.4 * Math.pow(ratio, 3)})`
      }

      phoneScaler(height,width,scrollTop)

    }

    const throttledAppScrollListener = throttle(appScrollListener, 20, { leading: true, trailing: true })

    const resizeScaleListener = debounce(() => {
      const scrollTop = global.isBodyScrolling ? document.body.scrollTop : document.scrollingElement.scrollTop
      const oh = document.documentElement.style.getPropertyValue("--oh")
      const height = parseFloat(oh.substring(0, oh.length - 2)) * 100
      phoneScaler(height, $(window).width(), scrollTop)
    }, 80)

    const resizePhoneListener = debounce(resizePhone, 50)

    onMounted(() => {
      resizePhone()
      window.addEventListener("resize", resizePhoneListener)
      const scrollTop = global.isBodyScrolling ? document.body.scrollTop : document.scrollingElement.scrollTop
      const oh = document.documentElement.style.getPropertyValue("--oh")
      const height = parseFloat(oh.substring(0, oh.length - 2)) * 100
      phoneScaler(height, $(window).width(), scrollTop)

      if(global.isBodyScrolling) {
        document.body.addEventListener("scroll", throttledAppScrollListener, { passive: true})
      } else {
        document.addEventListener("scroll", throttledAppScrollListener, { passive: true})
      }

      window.addEventListener("resize", resizeScaleListener)
    })

    onUnmounted(() => {
      window.removeEventListener("resize", resizePhoneListener)
      window.removeEventListener("resize", resizeScaleListener)
      if(global.isBodyScrolling) {
        document.body.removeEventListener("scroll", throttledAppScrollListener, { passive: true})
      } else {
        document.removeEventListener("scroll", throttledAppScrollListener, { passive: true})
      }
    })
    return {
      global
    }
  }
}
</script>

<style scoped>
#about {
  position: relative;
  display: initial;
  width: 100%;
  height: calc(var(--oh) * 200);
}
.about-container {
  position: relative;
  width: 100%;
  height: calc(var(--oh) * 100);
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.about-phone-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.phone-horizontal-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

#phone-outer-layer {
  z-index: 6;
  position: absolute;
  top: calc(var(--oh) * 50);
  left: 50vw;
  transform: translate(-50%, -50%) translateZ(1px);
  transform-origin: 0 0;
  will-change: transform;
  -webkit-touch-callout: none;
}

.phone-wrapper {
  width: 100%;
  height: calc(var(--oh) * 100);
  position: sticky;
  top: 0;
}
#background-wrapper {
  width: 100vw;
  height: calc(var(--oh) * 100);
  position: sticky;
  top: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-x: hidden;
}
.header-container h1 {
  font-weight: 700;
  font-size: calc(var(--oh) * 3.5);
  line-height: calc(var(--oh) * 4.3);
  color: #2E293D;
  margin: 0;
}

.about-phone-outer-layer {
  width: 65%;
  transform-origin: 50% 50%;
  max-width: 100%;
}

.homepage-spacer {
  height: calc(var(--oh) * 100);
}

.headers-wrapper {
  width: 100%;
  height: calc(var(--vh) * 100);
  padding: 1% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}


.header-container {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.badges-container {
  display: flex;
  justify-content: center;
}

.header-container span {
  margin-top: calc(var(--oh) * 2);
  font-weight: 500;
  font-size: calc(var(--oh) * 1.8);
  line-height: 150%;
  letter-spacing: 0.8px;
  color: #A7A5B2;
}


.homepage-item-top h1 {
  font-weight: bold;
  font-size: calc(var(--oh) * 3.7);
  line-height: 150%;
  text-align: center;
  /* identical to box height */

  margin: calc(var(--oh) * 1) 8vw;
  color: #2E293D;

}
.homepage-paper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}
#homepage-papers {
  position: absolute;
  height: 43%;
  top: 59%;
  left: 50%;
  transform: translate(-50%,-50%);
}
#homepage-background {
  position: absolute;
  overflow: hidden;
  top: calc(var(--oh) * 50);
  transform: translateY(-50%);
  border-radius: 20px;
  will-change: transform;
  user-select: none;
}

#homepage-background-image {
  position: absolute;
  height: calc(var(--oh) * 154.38);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.homepage-overlay {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: calc(var(--vh) * 100);
  top: 0;
  left: 0;
  padding-top: min(125px, 8vh);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
#homepage-content {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: calc(var(--oh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
  padding-top: calc(var(--oh) * 10);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.homepage-item-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-item-top span {
  font-weight: 500;
  font-size: calc(var(--oh) * 1.7);
  line-height: 150%;
  /* identical to box height, or 51px */

  text-align: center;
  letter-spacing: 0.002em;
  margin-top: 0;
  color: #A7A5B2;
}
.homepage-item-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.homepage-item-bottom span {
  font-weight: 500;
  font-size: calc(var(--oh) * 1.6);
  line-height: 150%;
  /* identical to box height, or 22px */

  text-align: center;
  letter-spacing: 0.002em;
  margin-bottom: 8px;

  color: #2E293D;
}
.swipe-down-arrow {
  width: 22px;
  height: 26px;
  transform-origin: bottom;
  animation: swipeDownAnim 1s infinite;
}

@keyframes swipeDownAnim {
  0% { transform: scaleY(1)}
  85% {transform: scaleY(0.6)}
  100% {transform: scaleY(1)}
}

</style>